var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Container, FiltersContainer, ContentContainer } from "../components/react-components/shared-components/Ui/Grid/Grid";
import { FilterCard } from "../components/react-components/shared-components/SideFilter/FilterCard";
import { View } from "../components/react-components/projects-components/Views/View";
import { MarketsProvider } from "./Context/MarketsContext";
import { Filters } from "../components/react-components/projects-components/Filters";
import { ThemeProvider } from "styled-components";
import Theme from "../components/react-components/Styles/Theme";
import GlobalStyles from "../components/react-components/Styles/Global";
import { CleanFilters } from "../components/react-components/projects-components/CleanFilters";
import { InfoBar } from "../components/react-components/projects-components/InfoBar";
import { ViewToggler } from "../components/react-components/projects-components/Views/ViewToggler";
import { SearchBar } from "../components/react-components/projects-components/SearchBar";
import { Pagination } from "../components/react-components/projects-components/Pagination";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
import { PageLoading } from "../components/react-components/shared-components/Loading/PageLoading";
var Markets = function () {
    React.useEffect(function () {
        var _a;
        // @ts-ignore
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        if ((langCode === null || langCode === void 0 ? void 0 : langCode.length) === 2) {
            i18next.changeLanguage(langCode);
        }
    }, []);
    return (_jsx(Suspense, __assign({ fallback: _jsx(PageLoading, {}) }, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsxs(MarketsProvider, { children: [_jsx(GlobalStyles, {}), _jsx(ThemeProvider, __assign({ theme: Theme }, { children: _jsx("div", __assign({ className: "pg-projects" }, { children: _jsxs(Container, { children: [_jsxs(FiltersContainer, { children: [_jsx(SearchBar, { application: "markets" }), _jsxs(FilterCard, { children: [_jsx(CleanFilters, { application: "markets" }), _jsx(Filters, { application: "markets" })] })] }), _jsxs(ContentContainer, { children: [_jsx(ViewToggler, { application: "markets" }), _jsx(InfoBar, { application: "markets" }), _jsx(View, { application: "markets" }), _jsx(Pagination, { application: "markets" })] })] }) })) }))] }) })) })));
};
var domNode = document.querySelector("#markets");
if (domNode) {
    var root = createRoot(domNode);
    root.render(_jsx(Markets, {}));
}
